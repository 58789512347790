@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@-webkit-keyframes autofill {
  to {
    color: #666;
    background: white !important;
  }
}

/*TODO: move this over to theme.js*/
.MuiPaper-root {
  outline: none;
}

/* ANIMATIONS */
@keyframes opacity-loop {
  0% { opacity: 0; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

input:-webkit-autofill::first-line,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: inherit !important;
  /*-webkit-box-shadow: 0 0 0 1000px white inset !important;*/
}
